// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'

import map from 'lodash/map'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import head from 'lodash/head'
import takeRight from 'lodash/takeRight'
import matches from 'lodash/matches'
import replace from 'lodash/replace'
import capitalize from 'lodash/capitalize'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Menu from 'antd/lib/menu'
import 'antd/lib/menu/style/css'

import Dropdown from 'antd/lib/dropdown'
import 'antd/lib/dropdown/style/css'

import { StaticQuery, graphql } from 'gatsby'
import { changeLocale, injectIntl } from 'gatsby-plugin-intl'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import makeTree from './makeTree'

import Link from '../link'
import '../link/style.less'

import website from '../../seo/website.json'

import smallKey from '../../methods/smallKey'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    allResources {
      edges {
        node {
          title {
            content
            lang
          }
          position
          routeSlug
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** TableOfContents */
const TableOfContents = ({
  uri,
  pageContext,
  expanded,
  expand,
  intl: { formatMessage, locale },
}) => {
  const menu = (
    <Menu>
      {map(website.languages, ({ name, locale }) => (
        <Menu.Item key={smallKey()}>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault()
              changeLocale(locale)
            }}
          >
            <small>{name}</small>
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const {
          allResources: { edges },
        } = data

        const nodes = map(edges, 'node')
        const nodes2 = filter(nodes, (o) => o.position < 20)
        const thisLocale = map(nodes2, (n) => ({
          ...n,
          title: filter(n.title, ['lang', locale])[0].content,
        }))
        const ordered = orderBy(thisLocale, ['position', 'asc'])
        const nodePositions = groupBy(map(ordered, 'position'), Math.floor)

        const treeData = {
          title: website.names[locale],
          key: '0-0-0',
          children: [],
        }
        map(nodePositions, (nodePosition, index) => {
          const hasChildren = nodePosition.length > 1
          const first = head(nodePosition, 1)
          const children =
            hasChildren === true
              ? takeRight(nodePosition, nodePosition.length - 1)
              : []
          const parent = filter(ordered, matches({ position: first }))[0]
          const treeNode = {
            title: `${index}. ${parent.title}`,
            key: `0-${replace(parent.position, '.', '-')}-0`,
            children: [],
            routeSlug: parent.routeSlug,
          }
          const parentIndex = index

          map(children, (child, index) => {
            const node = filter(ordered, matches({ position: child }))[0]

            treeNode.children.push({
              title: `${parentIndex}.${index + 1}. ${node.title}`,
              key: `0-${replace(node.position, '.', '-')}`,
              routeSlug: `${parent.routeSlug}#${node.routeSlug}`,
            })
          })

          treeData.children.push(treeNode)
        })

        return (
          <Fragment>
            <nav>
              <li>
                <Link to="/">
                  <small>{treeData.title}</small>
                </Link>
              </li>
              {makeTree(treeData.children, uri, pageContext, expanded, expand)}
            </nav>
            <div className="etc">
              <Link to="/about">
                <small>{formatMessage({ id: 'header.about' })}</small>
              </Link>
              <Link to="/options">
                <small>{formatMessage({ id: 'header.options' })}</small>
              </Link>
              <Dropdown overlay={menu}>
                <Link to="#" onClick={(e) => e.preventDefault()}>
                  <small>
                    {formatMessage({ id: 'header.language' })}&nbsp;(
                    {capitalize(locale)})
                  </small>
                </Link>
              </Dropdown>
              <Link to="/contribute">
                <small>{formatMessage({ id: 'header.contribute' })}</small>
              </Link>
              <Link to="/impressum">
                <small>{formatMessage({ id: 'header.impressum' })}</small>
              </Link>
              <Link to="/copyright">
                <small>{formatMessage({ id: 'header.copyright' })}</small>
              </Link>
            </div>
          </Fragment>
        )
      }}
    />
  )
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedTableOfContents = compose(
  injectIntl // Add state
)(TableOfContents)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedTableOfContents
