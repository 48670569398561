// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'

import map from 'lodash/map'
import filter from 'lodash/filter'
import endsWith from 'lodash/endsWith'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import isInteger from 'lodash/isInteger'
import noop from 'lodash/noop'
import head from 'lodash/head'
import last from 'lodash/last'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'

import classNames from 'classnames'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { navigate, injectIntl } from 'gatsby-plugin-intl'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Footer */
class Footer extends React.PureComponent {
  /** [constructor description] */
  constructor(props) {
    super(props)

    const { uri, nodes } = this.props

    const ordered = orderBy(nodes, ['position', 'asc'])
    const nodePositions = []
    map(groupBy(map(ordered, 'position'), Math.floor), (a) =>
      map(a, (v) => (isInteger(v) ? nodePositions.push(v) : noop()))
    )

    this.state = {
      nodePositions,
    }

    this.leftFx = this.leftFx.bind(this)
    this.rightFx = this.rightFx.bind(this)
  }

  /** [componentDidMount description] */
  componentDidMount() {
    document.addEventListener('keydown', this.leftFx, false)
    document.addEventListener('keydown', this.rightFx, false)
  }

  /** [componentWillUnmount description] */
  componentWillUnmount() {
    document.addEventListener('keydown', this.leftFx, false)
    document.addEventListener('keydown', this.rightFx, false)
  }

  /** [leftFx description] */
  leftFx(event) {
    if (event.keyCode === 37) {
      const { uri, nodes } = this.props
      const { nodePositions } = this.state

      const node = filter(nodes, (n) => endsWith(uri, n.routeSlug))[0]

      const currentNodePosition =
        isUndefined(node) === false ? Math.floor(node.position) : 0
      const prev =
        isUndefined(node) === false
          ? head(nodePositions) !== currentNodePosition
            ? filter(nodes, (n) => n.position === currentNodePosition - 1)[0]
            : null
          : null

      if (isNull(prev) === false) {
        navigate(prev.routeSlug)
      }
    }
  }

  /** [rightFx description] */
  rightFx(event) {
    if (event.keyCode === 39) {
      const { uri, nodes } = this.props
      const { nodePositions } = this.state

      const node = filter(nodes, (n) => endsWith(uri, n.routeSlug))[0]

      const currentNodePosition =
        isUndefined(node) === false ? Math.floor(node.position) : 0

      const next =
        isUndefined(node) === false
          ? last(nodePositions) !== currentNodePosition
            ? filter(nodes, (n) => n.position === currentNodePosition + 1)[0]
            : null
          : null

      if (isNull(next) === false) {
        navigate(next.routeSlug)
      }
    }
  }

  /** [render description] */
  render() {
    const { uri, nodes, intl } = this.props
    const { formatMessage, locale } = intl
    const { nodePositions } = this.state

    const node = filter(nodes, (n) => endsWith(uri, n.routeSlug))[0]
    const currentNodePosition =
      isUndefined(node) === false ? Math.floor(node.position) : -1
    const prev =
      isUndefined(node) === false
        ? head(nodePositions) !== currentNodePosition
          ? filter(nodes, (n) => n.position === currentNodePosition - 1)[0]
          : null
        : null
    const prevWithLocale =
      isNull(prev) === false
        ? {
            ...prev,
            title: filter(prev.title, ['lang', locale])[0].content,
          }
        : null
    const next =
      isUndefined(node) === false
        ? last(nodePositions) !== currentNodePosition
          ? filter(nodes, (n) => n.position === currentNodePosition + 1)[0]
          : null
        : null
    const nextWithLocale =
      isNull(next) === false
        ? {
            ...next,
            title: filter(next.title, ['lang', locale])[0].content,
          }
        : null

    const altnode =
      endsWith(uri, 'en') ||
      endsWith(uri, 'en/') ||
      endsWith(uri, 'de') ||
      endsWith(uri, 'de/') ||
      endsWith(uri, 'about') ||
      endsWith(uri, 'about/') ||
      endsWith(uri, 'options') ||
      endsWith(uri, 'options/') ||
      endsWith(uri, 'impressum') ||
      endsWith(uri, 'impressum/') ||
      endsWith(uri, 'contribute') ||
      endsWith(uri, 'contribute/') ||
      endsWith(uri, 'copyright') ||
      endsWith(uri, 'copyright/')

    const coverFooter =
      endsWith(uri, 'en') ||
      endsWith(uri, 'en/') ||
      endsWith(uri, 'de') ||
      endsWith(uri, 'de/')

    return (
      <Fragment>
        {isUndefined(node) === false > 0 && (
          <Fragment>
            <footer
              className={classNames({
                'cover-footer': coverFooter,
              })}
            >
              <div className="container">
                <div className="prev-next">
                  {head(nodePositions) !== currentNodePosition ? (
                    <Link
                      to={prevWithLocale.routeSlug}
                      className="prev"
                      aria-label={formatMessage({ id: 'footer.prev' })}
                    >
                      <span>← {prevWithLocale.title}</span>
                    </Link>
                  ) : (
                    <Link
                      to="/"
                      className="prev"
                      aria-label={formatMessage({ id: 'footer.home' })}
                    >
                      <span>← {formatMessage({ id: 'footer.home' })}</span>
                    </Link>
                  )}
                  {head(nodePositions) !== currentNodePosition && (
                    <Link
                      to="/"
                      className="top"
                      aria-label={formatMessage({ id: 'footer.home' })}
                    >
                      <span>{formatMessage({ id: 'footer.home' })}</span>
                    </Link>
                  )}
                  <Link
                    to={node.routeSlug}
                    className="chapter"
                    aria-label={formatMessage({ id: 'footer.home' })}
                  >
                    <span>{formatMessage({ id: 'footer.top' })}</span>
                  </Link>
                  {last(nodePositions) !== currentNodePosition ? (
                    <Link
                      to={nextWithLocale.routeSlug}
                      className="next"
                      aria-label={formatMessage({ id: 'footer.next' })}
                    >
                      <span>{nextWithLocale.title} →</span>
                    </Link>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </div>
                <div className="copyright-notices">
                  <p>
                    <span>©</span>&nbsp;
                    <Link to="https://www.auranetwork.app/">
                      <span>Aura Network</span>
                    </Link>
                    &nbsp;&&nbsp;
                    <Link to="https://books.prisma.haus/en/">
                      <span>Prisma Books</span>
                    </Link>
                    <br />
                    <Link to="#">
                      {formatMessage({ id: 'footer.onGift' })}&nbsp;
                    </Link>
                  </p>
                  <p>
                    {formatMessage({ id: 'footer.publishedBy' })}&nbsp;
                    <Link to="https://books.prisma.haus/en/">
                      <span>Prisma Books</span>
                    </Link>
                    <br />
                    <span>
                      {formatMessage({ id: 'footer.handcraftedBy' })}&nbsp;
                    </span>
                    <Link to="https://design.prisma.haus/en/">
                      <span>Prisma Design</span>
                    </Link>
                  </p>
                </div>
              </div>
            </footer>
          </Fragment>
        )}
        {altnode === true && (
          <footer
            className={classNames({
              'cover-footer': coverFooter,
            })}
          >
            <div className="container">
              <div className="copyright-notices">
                <p>
                  <span>©</span>&nbsp;
                  <Link to="https://www.auranetwork.app/">
                    <span>Aura Network</span>
                  </Link>
                  &nbsp;&&nbsp;
                  <Link to="https://books.prisma.haus/en/">
                    <span>Prisma Books</span>
                  </Link>
                  <br />
                  <Link to="#">
                    {formatMessage({ id: 'footer.onGift' })}&nbsp;
                  </Link>
                </p>
                <p>
                  {formatMessage({ id: 'footer.publishedBy' })}&nbsp;
                  <Link to="https://books.prisma.haus/en/">
                    <span>Prisma Books</span>
                  </Link>
                  <br />
                  {formatMessage({ id: 'footer.handcraftedBy' })}&nbsp;
                  <Link to="https://design.prisma.haus/en/">
                    <span>Prisma Design</span>
                  </Link>
                </p>
              </div>
            </div>
          </footer>
        )}
      </Fragment>
    )
  }
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedFooter = compose(
  injectIntl // Add state
)(Footer)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedFooter
