// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'

import filter from 'lodash/filter'
import endsWith from 'lodash/endsWith'
import isUndefined from 'lodash/isUndefined'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { injectIntl } from 'gatsby-plugin-intl'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import FixedTOC from '../table-of-contents/fixed-toc'
import '../table-of-contents/style.less'

import Link from '../link'
import '../link/style.less'

import Slider from './slider'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** FixedHeader */
class FixedHeader extends React.PureComponent {
  /** [constructor description] */
  constructor(props) {
    super(props)

    const { uri, nodes, pageContext } = this.props
    const node = filter(nodes, (n) => endsWith(uri, n.routeSlug))[0]
    let expanded = pageContext.intl.originalPath

    if (isUndefined(node) === false) {
      expanded = node.routeSlug
    }

    this.state = {
      isActive: false,
      whatsActive: 'root',
      expanded,
    }

    this.update = this.update.bind(this)
    this.expand = this.expand.bind(this)
    this.linkRef = React.createRef()
  }

  /** [update description] */
  update(isActive, whatsActive) {
    this.setState({ isActive, whatsActive })
  }

  /** [update description] */
  expand(expanded) {
    this.setState({ expanded })
  }

  /** [render description] */
  render() {
    const {
      uri,
      nodes,
      pageContext,
      intl: { locale },
    } = this.props
    const { isActive, whatsActive, expanded } = this.state

    const node = filter(nodes, (n) => endsWith(uri, n.routeSlug))[0]
    const thisLocale =
      isUndefined(node) === false
        ? {
            ...node,
            title: filter(node.title, ['lang', locale])[0].content,
          }
        : undefined

    const altnode =
      endsWith(uri, 'about') ||
      endsWith(uri, 'about/') ||
      endsWith(uri, 'options') ||
      endsWith(uri, 'options/') ||
      endsWith(uri, 'impressum') ||
      endsWith(uri, 'impressum/') ||
      endsWith(uri, 'contribute') ||
      endsWith(uri, 'contribute/') ||
      endsWith(uri, 'copyright') ||
      endsWith(uri, 'copyright/')

    const isNode = isUndefined(node) === false || altnode === true

    return (
      <Fragment>
        {isNode === true && (
          <Fragment>
            <header>
              <div className="fixed">
                <FixedTOC
                  uri={uri}
                  pageContext={pageContext}
                  expanded={expanded}
                  expand={this.expand}
                />
              </div>
              <div className="fixed-smaller-screen">
                <div className="container">
                  <div className="buttons">
                    {altnode === false && (
                      <div className="chapter">
                        <Link
                          className="hidden-when-smaller-than-container"
                          to={
                            isUndefined(thisLocale) === false
                              ? thisLocale.routeSlug
                              : '#'
                          }
                          aria-label={
                            isUndefined(thisLocale) === false
                              ? thisLocale.title
                              : 'Page'
                          }
                        >
                          {isUndefined(thisLocale) === false > 0 ? (
                            <span>{thisLocale.title}</span>
                          ) : (
                            <Fragment>
                              <span>&nbsp;</span>
                            </Fragment>
                          )}
                        </Link>
                      </div>
                    )}
                    <div ref={this.linkRef} className="contents">
                      <Link
                        onClick={(e) => {
                          e.preventDefault()
                          if (isActive === false) {
                            this.update(true, 'table-of-contents')
                          }
                          if (isActive === true) {
                            this.update(false, 'root')
                          }
                        }}
                        to="#"
                        aria-label="Contents"
                      >
                        <span>Contents</span>
                      </Link>
                    </div>
                    <Link
                      to="/contribute"
                      className="contribute"
                      aria-label="Contribute"
                    >
                      <span>Contribute</span>
                    </Link>
                    <Link to="/about" className="about" aria-label="About">
                      <span>About</span>
                    </Link>
                  </div>
                </div>
              </div>
            </header>
            <Slider
              isActive={isActive}
              whatsActive={whatsActive}
              update={this.update}
              linkRef={this.linkRef}
            />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedFixedHeader = compose(
  injectIntl // Add state
)(FixedHeader)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedFixedHeader
