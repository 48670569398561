// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { useMediaQuery } from 'react-responsive'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import MediaState from '../media-state'

import MessageBus from '../message-bus'
import '../message-bus/style.less'

import StandardHeader from '../header/standard-header'
import FixedHeader from '../header/fixed-header'
import '../header/style.less'

import Footer from '../footer'
import '../footer/style.less'

import InitalMetadata from '../schema/inital-metadata'
import UpdateTitle from '../schema/update-title'
import OrganisationSchema from '../schema/organisation-schema'
import WebsiteSchema from '../schema/website-schema'

import { updateMediaState, updateScreenSizeState } from '../../state/actions'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Styles
import '../../styles/index.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

export const query = graphql`
  query {
    allResources {
      nodes {
        title {
          content
          lang
        }
        position
        routeSlug
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Layout */
class Layout extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.state = {
      client: false,
    }
  }

  /** after mount */
  componentDidMount() {
    this.setState({ client: true })
  }

  /** on mount */
  componentDidUpdate() {
    if (typeof window !== 'undefined') {
      if (this.state.client === true) {
        const element = document.getElementById('layout')
        element.scrollTop = 0
      }
    }
  }

  /** standard renderer */
  render() {
    const { client } = this.state
    const {
      children,
      uri = '',
      mediaState,
      screenSizeState,
      updateMediaState,
      updateScreenSizeState,
      className = 'standard-page',
      websiteState,
      pageContext,
    } = this.props
    const { type, mode, nav, fixedNavSide } = websiteState

    return (
      <Fragment>
        <MediaState
          className={`layout ${className} ${type}-type ${mode}-mode ${nav}-nav nav-is-${fixedNavSide}`}
          id="layout"
          data-uri={uri}
          useMediaQuery={useMediaQuery}
          updateMediaState={updateMediaState}
          screenSizeState={screenSizeState}
          updateScreenSizeState={updateScreenSizeState}
          client={client}
        >
          <InitalMetadata />
          <UpdateTitle />
          <OrganisationSchema />
          <WebsiteSchema />
          <StaticQuery
            query={query}
            render={({ allResources: { nodes } }) => (
              <Fragment>
                {nav === 'standard' && (
                  <StandardHeader nodes={nodes} uri={uri} />
                )}
                {nav === 'fixed' && (
                  <FixedHeader
                    nodes={nodes}
                    uri={uri}
                    pageContext={pageContext}
                  />
                )}
                <div className="rest">
                  <main>
                    <div className="placeholder" />
                    {children}
                  </main>
                  <Footer nodes={nodes} uri={uri} />
                </div>
              </Fragment>
            )}
          />
        </MediaState>
        <MessageBus />
      </Fragment>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
    screenSizeState: state.screenSizeState,
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateMediaState(payload) {
      dispatch(updateMediaState(payload))
    },
    updateScreenSizeState(payload) {
      dispatch(updateScreenSizeState(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedLayout = compose(
  withState // Add state
)(Layout)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedLayout
